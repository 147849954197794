.footer {
    background: #001E5E;
    padding: 30px 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .footer-logo, .footer-hrh-logo {
        padding: 0 25px;

        img {
            width: 80px;
            height: 80px;

            @media(min-width: $min-tablet) {
                width: 150px;
                height: 150px;
            }
        }
    }

    .footer-content {
        width: 80%;
        text-align: center;
    }
}

.footer .row {
    width: 100%;
    margin: 1% 0%;
    padding: 0.6% 0%;
    color: #FFFFFF;
    font-size: 0.8em;
}

.footer .row a {
    text-decoration: none;
    color: #FFFFFF;
    transition: 0.5s;
}

.footer .row a:hover {
    color: #36C593;
}

.footer .row ul {
    width: 100%;
}

.footer .row ul li {
    display: inline-block;
    margin: 0px 30px;
}

.footer .row a svg {
    margin: 0 auto;
    transition: all 1s;
    transform-origin: 50% 50%;

    &.social-icon {
        color: #FFFFFF;
        width: 20px;
        height: 20px;
        fill: #FFFFFF;
        display: block;
    }

    &:hover {
        color: #36C593;
        transform: scale(1.2);
    }
}

@media (max-width: 720px) {
    .footer {
        text-align: left;
        padding: 2%;
    }

    .footer .row ul li {
        display: block;
        margin: 10px 0px;
        text-align: left;
    }

    .footer .row a i {
        margin: 0% 3%;
    }
}
