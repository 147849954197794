//declare fonts here and assign to variables below
//Ensure that you are preloading to the first file in each @font-face rule to improve page speed
//https://nooshu.github.io/blog/2021/01/23/the-importance-of-font-face-source-order-when-used-with-preload/

/* roboto-regular - 400 */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap; //Don't forget this!
  src:
  url('/assets/fonts/roboto-v27-latin-regular.woff2') format('woff2'),
  url('/assets/fonts/roboto-v27-latin-regular.woff') format('woff'),
  url('/assets/fonts/roboto-v27-latin-regular.ttf') format('truetype'),
}

/* roboto-regular - 700 */
@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap; //Don't forget this!
  src:
  url('/assets/fonts/roboto-v27-latin-700.woff2') format('woff2'),
  url('/assets/fonts/roboto-v27-latin-700.woff') format('woff'),
  url('/assets/fonts/roboto-v27-latin-700.ttf') format('truetype'),
}

/* Magical Night - 400 */
@font-face {
    font-family: 'Magical Night';
    font-style: normal;
    font-weight: 400;
    font-display: swap; //Don't forget this!
    src: url('/assets/fonts/Magical-Night.woff2') format('woff2'), url('/assets/fonts/Magical-Night.woff') format('woff'), url('/assets/fonts/Magical-Night.ttf') format('truetype'),
}

/* Cirkus - 400 */
@font-face {
    font-family: 'PaytoneOne';
    font-style: normal;
    font-weight: 400;
    font-display: swap; //Don't forget this!
    src: url('/assets/fonts/PaytoneOne-Regular.woff2') format('woff2'), url('/assets/fonts/PaytoneOne-Regular.woff') format('woff'), url('/assets/fonts/PaytoneOne-Regular.ttf') format('truetype'),
}



$regular: 'Roboto', Helvetica, Arial, sans-serif;
$light: 'Roboto', Helvetica, Arial, sans-serif;
$bold: 'Roboto Bold', Helvetica, Arial, sans-serif;
$italic: 'Roboto', Helvetica, Arial, sans-serif;
$magical-night: 'Magical Night', Helvetica, Arial, sans-serif;
$paytoneOne: 'PaytoneOne', Helvetica, Arial, sans-serif;

//layout

$vertical-space: 80px;
$horizontal-space: 32px;

$vertical-space-mob: 40px;
$horizontal-space-mob: 32px;

$curve-mob: 80px;
$curve-desktop: 160px;

$spacing: 32px;  // standard vertical spacing between elements, buttons etc

$base-font-size: 16;

$max-desktop: 1200px;
$min-desktop: 980px;
$min-tablet: 768px;
$min-mobile-landscape: 600px;
$small: 480px;
$tiny: 360px;


$max-content-width: 1200px;

///put z-index info here

$block-padding: 32px;


$grid-spacing: 16px; //make this 50% of spacing
$spacing-small: 24px;

//buttons

$button-padding: 16px 32px;




//ANIMATIONS 
$duration: .2s;
$duration-slow: .6s;

$easing: linear;


//colours
$titles: #000000;

$text: #454545;
$text-light: #868686; //#868686;
$black: #000000;
$white: #FFFFFF;
$text-opacity: rgba(0, 0, 0, .7);
$accent: #36C593;  //green #36C593;
$accent-hover: #000;
$accent-text: #FFF; // text colour on accent bg
$accent-hover-text: #FFF;
$accent-gradient-to: #CFEAFF; // text colour on accent bg
$border: #DBDBDB;

$disabled-hover: #ECECEC;

//footer vars

$footer-spacing-mob: 40px 0;
$footer-spacing: 100px 0;
$disclaimer-spacing-mob: 20px 0;
$disclaimer-spacing: 30px 0;

$footer: #2A2A2A;
$footer-text: #FFF;
$disclaimer-text: rgba(255, 255, 255, .6);
$disclaimer: #161616;

//hero
$hero-color: #FFF;
$hero-height: 100vh;
$hero-width: 100vw;
$hero-bg-mix: rgba(#000000, 0.3); // hide with false

//slider
$slick-dot-width: 20px;
$slick-dot-height: 20px;
$slick-dot-color: white;
$slick-dot-color-active: $slick-dot-color;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;
$animationSpeed: 40s;

$speed: 0.6s;
$delay: ($speed * .5);
$easing: cubic-bezier(.55,0,.1,1);