﻿h1 {
    text-align: center;
}
.confettis {
    position: absolute;
    height: 100vw;
    max-height: 100vh;
    width: 100vw;
    max-width: 100%;

    > .confetti {
        left: 50%;
        width: 16px;
        height: 16px;
        position: absolute;
        transform-origin: left top;
        animation: confetti 5s ease-in-out -2s infinite;
    }

    .confetti:nth-child(1) {
        left: 10%;
        animation-delay: 0;
        background-color: #FC0120;
    }

    .confetti:nth-child(2) {
        left: 20%;
        animation-delay: -5s;
        background-color: #8257E6;
    }

    .confetti:nth-child(3) {
        left: 30%;
        animation-delay: -3s;
        background-color: #FFBF4D;
    }

    .confetti:nth-child(4) {
        left: 40%;
        animation-delay: -2.5s;
        background-color: #FE5D7A;
    }

    .confetti:nth-child(5) {
        left: 50%;
        animation-delay: -4s;
        background-color: #45EC9C;
    }

    .confetti:nth-child(6) {
        left: 60%;
        animation-delay: -6s;
        background-color: #F6E327;
    }

    .confetti:nth-child(7) {
        left: 70%;
        animation-delay: -1.5s;
        background-color: #F769CE;
    }

    .confetti:nth-child(8) {
        left: 80%;
        animation-delay: -2s;
        background-color: #007DE7;
    }

    .confetti:nth-child(9) {
        left: 90%;
        animation-delay: -3.5s;
        background-color: #63B4FC;
    }

    .confetti:nth-child(10) {
        left: 100%;
        animation-delay: -2.5s;
        background-color: #F9C4EA;
    }
}

.btn {
    transition: all .5s ease-out;
    background-color: #001E5E;
    padding: 14px 24px;
    border-radius: 32px;
    width: fit-content;
    display: block;
    color: #FFFFFF;
    margin: 0 auto;

    @media(min-width: $min-desktop) {
        margin: initial;
    }

    &:hover {
        color: #001E5E;
        border-color: #36C593;
        background-color: #36C593;
        text-decoration: none;
    }
}

.rte-content {
    h2 {
        font-family: $paytoneOne;
        text-align: center;
        color: #001E5E;

        @media(min-width: $min-tablet) {
            @include font-size(40);
        }
    }

    p {
        text-align: center;
    }
}

@keyframes confetti {
    0% {
        transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
    }

    25% {
        transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
    }

    50% {
        transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
    }

    75% {
        transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
    }

    100% {
        transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
    }
}

.intro-section {
    display: flex;
    flex-direction: column;

    @media(min-width: $min-tablet) {
        flex-direction: row;
    }

    > div {
        flex: 1;
        width: 100%;

        @media(min-width: $min-tablet) {
            width: 50%;
        }
    }
}
.section-slider {
    position: relative;

    .slider {
        width: 100%;
        height: 55vh;
        overflow: hidden;
        position: relative;

        &__slide {
            width: 100%;
            height: 100%;
            display: flex;
            position: absolute;
            top: 0;
            left: 0;

            &--1 {
                z-index: 6;
            }

            &--2 {
                z-index: 5;
            }

            &--3 {
                z-index: 4;
            }

            &--4 {
                z-index: 3;
            }

            &--5 {
                z-index: 2;
            }

            &--6 {
                z-index: 1;
            }
        }

        &__img {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(120deg,#CCD2DE,#001E5E);
                opacity: .5;
                z-index: 5;
            }

            &--1 {
                background-image: url('https://addingham-gala.co.uk/assets/imgs/carousel-promo.jpg');
            }

            &--2 {
                background-image: url('https://addingham-gala.co.uk/assets/imgs/inflatable-laser-tag.jpg');
            }

            &--3 {
                background-image: url('https://addingham-gala.co.uk/assets/imgs/gala-promo.jpg');
            }

            &--4 {
                background-image: url('https://addingham-gala.co.uk/assets/imgs/guisley-brothers.jpg');
            }

            &--5 {
                background-image: url('https://addingham-gala.co.uk/assets/imgs/drink-promo.jpg');
            }

            &--6 {
                background-image: url('https://addingham-gala.co.uk/assets/imgs/music-hummingbirds.jpeg');
            }

            &--7 {
                background-image: url('https://addingham-gala.co.uk/assets/imgs/train.jpg');
            }
        }

        &__text {
            align-self: flex-end;
            padding: 0 0 5vw 4vh;
            opacity: 0;
            width: 80%;
            max-width: 1005px;

            @media(min-width: $min-tablet) {
                padding: 0 0 5vw 15vh;
            }

            .slider__header {
                @include font-size(16,18);
                margin-bottom: 40px;
                text-transform: capitalize;
                color: $white;


                @media(min-width: $min-tablet) {
                    @include font-size(32);
                }
            }

            .cta {
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 6px;
                margin-left: 65px;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -55px;
                    width: 40px;
                    height: 1px;
                    background-color: white;
                }
            }
        }
        // Slider Navigation
        &__navigation {
            width: 21px;
            height: 400px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: calc(100% - 7vw);
            z-index: 10;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            @media(min-width: $min-tablet) {
                left: calc(100% - 5vw);
            }
        }

        .count--top {
            position: absolute;
            top: 0;
            left: 0;
            color: $white;
        }

        .count {
            opacity: 0;
        }

        .count:first-child {
            opacity: 1;
        }

        .count--bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            color: $white;
        }

        &__bar {
            width: 2px;
            height: 250px;
            position: relative;

            &--dynamic {
                width: 100%;
                height: 100%;
                background-color: #001E5E;
                transform-origin: top center;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
            }

            &--static {
                width: 100%;
                height: 100%;
                background-color: darkgrey;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}

.events-agenda {

    .btn {
        margin: 0 auto;
    }

    h2 {
        font-family: $paytoneOne;
        text-align: center;
        color: #001E5E;
        margin: 0;

        @media(min-width: $min-tablet) {
            @include font-size(40);
        }
    }

    ul.main {
        list-style: none;
        max-width: 100%;
        margin: 20px auto;
        padding-left: 0;

        @media(min-width: $min-tablet) {
            max-width: 75%;
        }



        .date {
            width: 100%;
            padding: 15% 1% 0 0;
            float: left;
            display: flex;
            align-items: center;
            flex-direction: row;

            & > div {
                flex: 1;
            }

            @media(min-width: $min-tablet) {
                width: 17%;
                flex-direction: column;
            }

            h3 {
                padding: 0;
                margin: 0;
                color: #001E5E;


                @media(min-width: $min-tablet) {
                    font-size: 1.5em;
                }
            }

            p {
                font-size: .8em;
                color: #333333;
            }

            .graphic-icon {
                width: 100%;
                height: 100%;
                transform: scale(3);
            }
        }

        .events {
            float: left;
            width: 95%;
            border-left: 1px solid #CCC;
            margin-top: 10%;
            padding-top: 3%;

            @media(min-width: $min-tablet) {
                width: 80%;
            }

            .events-detail {
                max-width: 550px;
                padding-left: 0;

                @media(min-width: $min-tablet) {
                    @include font-size(16);
                    padding-left: 40px;
                }

                li {
                    padding: 10px;
                    border-bottom: 1px dashed #CCC;
                    line-height: 22px;
                    transition: ease .4s all;
                    display: flex;
                    color: #333333;

                    &:hover {
                        background: #CCD2DE;
                    }

                    .event-link {
                        @include font-size(14);
                        font-weight: 600;
                        text-decoration: underline;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .event-time {
                        color: #001E5E;
                        font-weight: 900;
                        width: 90px;
                        display: block;

                        @media(min-width: $min-tablet) {
                            width: 80px;
                        }
                    }

                    .event-location {
                        font-size: .8em;
                        color: #36C593;
                    }
                }

                li:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}
.sponsor-title {
    font-family: $paytoneOne;
    text-align: center;
    color: #36C593;

    @media(min-width: $min-tablet) {
        @include font-size(40);
    }
}

.slider {
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: auto;



    .slide-track {
        animation: scroll $animationSpeed linear infinite;
        display: flex;
        width: calc(250px * 14);
    }

    .slide {
        height: 100px;
        width: 250px;
    }
}

.slider-sponsers {

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(calc(-250px * 7))
        }
    }

    .slider {
        height: 100px;
        margin: auto;
        overflow: hidden;
        position: relative;
        width: auto;

        .slide-track {
            animation: scroll $animationSpeed linear infinite;
            display: flex;
            width: calc(250px * 14);
        }

        .slide {
            height: 100px;
            width: 250px;
        }
    }
}

.subtitle {
    font-family: $paytoneOne;
    text-align: center;
}
.sponsorship-section {
    background-color: #CCD2DE;
    margin: 0;

    .content {
        padding: 48px 0;
    }

    .sponsorship-packages {
        display: flex;
        flex-direction: column;


        @media(min-width: $min-desktop) {
            flex-direction: row;
            padding-top: 25px;
        }

        > div {
            flex: 1;
        }

        h3 {
            @include font-size(20,24);
            font-family: $paytoneOne;
            color: #001E5E;
            text-align: center;
        }

        .mob-bunting {
            img {
                width: 282px;
                height: 84px;
                margin: 0 auto;
                padding-bottom: 15px;

                @media(min-width: $min-desktop) {
                    display: none;
                }
            }
        }
    }
}

.location-section {
    margin: 0px;

    .location-wrapper {
        display: flex;
        flex-direction: column;
        text-align: center;

        .btn {
            margin: 0 auto;
            margin-top: 32px;

            @media(min-width: 1230px) {
                text-align: left;
                margin: initial;
            }
        }

        @media(min-width: 1230px) {
            flex-direction: row;
            text-align: left;
        }

        h2 {
            font-family: $paytoneOne;
            color: #001E5E;

            @media(min-width: 1240px) {
                @include font-size(36);
            }
        }

        h3 {
            @include font-size(26);
            font-family: $paytoneOne;
            color: #001E5E;
        }

        .map-info {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-image: url('https://addingham-gala.co.uk/assets/imgs/map-marker.jpg');
            width: 100%;
            height: 300px;
            order: 2;

            @media(min-width: 1240px) {
                width: 40%;
                height: 500px;
                order: 1;
            }
        }

        .location-detail, .admission-detail {
            padding: 25px;
            flex: 1;

            @media(min-width: 1240px) {
                padding: 50px;
            }
        }

        .location-detail {
            order: 3;

            @media(min-width: 1240px) {
                order: 2;
            }
        }

        .admission-detail {
            order: 1;

            @media(min-width: 1240px) {
                order: 3;
            }

            p {
                &.wristbands {
                    max-width: 600px;
                    margin: 16px auto;
                }

                span {
                    font-family: $paytoneOne;
                    color: #36C593;
                }
            }
        }
    }
}

.gallery {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20ch, 1fr));
    column-gap: 1rem;

    figure {
        --gallery-height: 8rem;
        margin: 0;
        height: var(--gallery-height);
        background-color: #CCD2DE;

        img {
            display: block;
            width: 100%;
            object-fit: contain;
            height: var(--gallery-height);
        }

        @media(min-width: $min-desktop) {
            --gallery-height: 15rem;
        }
    }
}

@supports (aspect-ratio: 1) {
    .gallery figure,
    .gallery img {
        height: auto;
    }
}


/**
 * Overlay
 * -- only show for tablet and up
 */
@media only screen and (min-width: 40em) {

    .modal-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        background-color: rgba(#000, 0.6);
        opacity: 0;
        visibility: hidden;
        backface-visibility: hidden;
        transition: opacity $speed $easing, visibility $speed $easing;

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }
}
// media query



/**
 * Modal
 */
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    background-color: #FFF;
    width: 600px;
    max-width: 75rem;
    min-height: 20rem;
    padding: 40px;
    border-radius: 3px;
    opacity: 0;
    overflow-y: auto;
    visibility: hidden;
    box-shadow: 0 2px 10px rgba(#000, 0.1);
    backface-visibility: hidden;
    transform: scale(1.2);
    transition: all $speed $easing;

    .close-modal {
        position: absolute;
        cursor: pointer;
        top: 5px;
        right: 15px;
        opacity: 0;
        backface-visibility: hidden;
        transition: opacity $speed $easing, transform $speed $easing;
        transition-delay: $delay;

        svg {
            width: 1.75em;
            height: 1.75em;
        }
    }
    // close modal

    .modal-content {
        opacity: 0;
        backface-visibility: hidden;
        transition: opacity $speed $easing;
        transition-delay: $delay;

        h3 {
            @include font-size(26);
            font-family: $paytoneOne;
            color: #001E5E;
        }
    }
    // content

    &.active {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
        border-radius: 36px;

        .modal-content {
            opacity: 1;
        }

        .close-modal {
            transform: translateY(10px);
            opacity: 1;
        }
    }
}



/**
 * Mobile styling
 */
@media only screen and (max-width: 39.9375em) {

    h1 {
        font-size: 1.5rem;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-overflow-scrolling: touch;
        border-radius: 0;
        transform: scale(1.1);
        padding: 0 !important;
    }

    .close-modal {
        right: 20px !important;
    }
}
.stalls-title {
    font-family: $paytoneOne;
    text-align: center;
    color: #001E5E;

    @media(min-width: $min-tablet) {
        @include font-size(40);
    }
}

.container-grid {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 290px));
    grid-gap: 1rem;
    padding: 1rem;
    justify-content: center;

    img {
        background-position: top;
        background-size: cover;
        height: 200px;
        width: 290px;
        box-shadow: 0 2px 10px #000;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
            box-shadow: none;
            transform: scale(1.1);
        }
    }
}






